import React from "react"
import SEO from "components/seo"
import Heading from "components/Heading"
import Img from "components/Img"
import HeroImageFull from "components/HeroImageFull"
import Interweave from "interweave"

const ViewServices = ({ pageData }) => {
  const {
    page,
    heading,
    brandStrategy,
    creativeDirection,
    design,
    brandIdentity,
    communication,
    architecture,
    packaging,
    advertising,
    innovation,
    crisisManagement,
    mediaTraining,
    salesLeads,
    capitalRaising,
  } = pageData;

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--service--hero">
        <HeroImageFull
          heroTextClassname="mobile--is-center"
          imageName="servicesHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="is-heading">{heading}</Heading>
        </HeroImageFull>
      </div>
      <div className="max-container wrapper--service--brand-strategy">
        <div className="max-1048 container container-with-background left">
          <div className="col-full">
            <Heading>{brandStrategy.title}</Heading>
          </div>
          <div className="col-image">
            <Img
              className=""
              name="servicesImageBrandStrategy"
              isFluid={true}
              alt={brandStrategy.title}
            />
          </div>
          <div className="col-content">
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={brandStrategy.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={brandStrategy.content} />
            </div>
          </div>
        </div>
      </div>
      <div className="max-container wrapper--service--creative-direction is-dark-bg">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <Img
              className=""
              name="servicesImageCreativeDirection"
              isFluid={true}
              alt={creativeDirection.title}
            />
          </div>
          <div className="col-content">
            <Heading>
              <Interweave content={creativeDirection.title} />
            </Heading>
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={creativeDirection.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={creativeDirection.content} />
            </div>
          </div>
        </div>
      </div>
      <div className="max-container wrapper--service--design">
        <div className="max-1048 container container-with-background left">
          <div className="col-full">
            <Heading>
              <Interweave content={design.title} />
            </Heading>
          </div>
          <div className="col-image">
            <Img
              className=""
              name="servicesImageDesign"
              isFluid={true}
              alt={design.title}
            />
          </div>
          <div className="col-content">
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={design.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={design.content} />
            </div>
          </div>
        </div>
      </div>
      <div className="max-container wrapper--service--branding">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <Img
              className=""
              name="servicesImageBrandIdentity"
              isFluid={true}
              alt={brandIdentity.title}
            />
          </div>
          <div className="col-content">
            <Heading>
              <Interweave content={brandIdentity.title} />
            </Heading>
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={brandIdentity.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={brandIdentity.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--communication is-dark-bg">
        <div className="max-1048 container container-with-background left">
          <div className="col-image">
            <Img
              className=""
              name="servicesImageCommunication"
              isFluid={true}
              alt={communication.title}
            />
          </div>
          <div className="col-content">
            <Heading>
              <Interweave content={communication.title} />
            </Heading>
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={communication.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={communication.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--architecture">
        <div className="max-1048 container container-with-background left">
          <div className="col-full">
            <Heading>
              <Interweave content={architecture.title} />
            </Heading>
          </div>
          <div className="col-image">
            <Img
              className=""
              name="servicesImageArchitecture"
              isFluid={true}
              alt={architecture.title}
            />
          </div>
          <div className="col-content">
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={architecture.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={architecture.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--packaging">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <Img
              className=""
              name="servicesImagePackaging"
              isFluid={true}
              alt={packaging.title}
            />
          </div>
          <div className="col-content">
            <Heading>
              <Interweave content={packaging.title} />
            </Heading>
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={packaging.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={packaging.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--advertising is-dark-bg">
        <div className="max-1048 container container-with-background left">
          <div className="col-image">
            <Img
              className=""
              name="servicesImageAdvertising"
              isFluid={true}
              alt noWrap={advertising.content}
            />
          </div>
          <div className="col-content">
            <Heading>
              <Interweave content={advertising.title} />
            </Heading>
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={advertising.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={advertising.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--innovation-labs">
        <div className="max-1048 container container-with-background left">
          <div className="col-full">
            <Heading><Interweave content={innovation.title} /></Heading>
          </div>
          <div className="col-image">
            <Img
              className=""
              name="servicesImageInnovationLabs"
              isFluid={true}
              alt={innovation.title}
            />
          </div>
          <div className="col-content">
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={innovation.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={innovation.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--crisis-management">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <Img
              className=""
              name="servicesImageCrisisMngt"
              isFluid={true}
              alt={crisisManagement.title}
            />
          </div>
          <div className="col-content">
            <Heading>
              <Interweave content={crisisManagement.title} />
            </Heading>
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={crisisManagement.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={crisisManagement.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--media-training is-dark-bg">
        <div className="max-1048 container container-with-background left">
          <div className="col-image">
            <Img
              className=""
              name="servicesImageMediaTraining"
              isFluid={true}
              alt={mediaTraining.title}
            />
          </div>
          <div className="col-content">
            <Heading>
              <Interweave content={mediaTraining.title} />
            </Heading>
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={mediaTraining.overview} />
            </div>
            <div className="content-body">
              <Interweave noWrap content={mediaTraining.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--sales-leads">
        <div className="max-1048 container container-with-background left">
          <div className="col-full">
            <Heading>
              <Interweave content={salesLeads.title} />
            </Heading>
          </div>
          <div className="col-image">
            <Img
              className=""
              name="servicesImageSalesLeads"
              isFluid={true}
              alt={salesLeads.title}
            />
          </div>
          <div className="col-content">
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={salesLeads.overview} />
            </div>
            <div className="content-body">
              <Interweave content={salesLeads.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--service--capital-raising">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <Img
              className=""
              name="servicesImageCaptitalRaising"
              isFluid={true}
              alt={capitalRaising.title}
            />
          </div>
          <div className="col-content">
            <Heading>
              <Interweave content={capitalRaising.title} />
            </Heading>
            <div className="content-background">
              <Interweave allowList={['strong']} noWrap content={capitalRaising.overview} />
            </div>
            <div className="content-body">
              <Interweave content={capitalRaising.content} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewServices
